<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <new-club />
          </div>
        </v-card-title>
        <v-card-title>
          Clubs
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="allClubs"
          item-key="name"
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.smcs="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                <template v-if="!item.stateUsers">
                  No Assigned Users
                </template>
                <template v-else>
                  {{ item.stateUsers.map(smc => smc.contact.preferredName).join(',') }}
                </template>
              </p>
            </div>
          </template>
          <template v-slot:item.states="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                <template v-if="!item.state">
                  No Assigned States
                </template>
                <template v-else>
                  {{ item.state.map(state => state.name).join(',') }}
                </template>
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="editClub(item)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="removeClub(item._id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.badge="{item}">
            <template v-if="item.badge === 'Active'">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left
                >
                  mdi-check
                </v-icon>
                {{ item.badge }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left
                >
                  mdi-close
                </v-icon>
                {{ item.badge }}
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import NewClub from './NewClub'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Clubs',
    },
    components: {
      NewClub,
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: 'Club',
            align: '',
            sortable: false,
            value: 'name',
          },
          {
            text: 'SMCs',
            align: '',
            sortable: false,
            value: 'smcs',
          },
          {
            text: 'States',
            align: '',
            sortable: false,
            value: 'states',
          },
          {
            text: 'Status',
            align: '',
            sortable: false,
            value: 'status',
          },
          {
            text: 'Action',
            sortable: false,
            value: 'action',
          },
        ],
      }
    },
    created () {
      this.$store.dispatch('getAllClubs')
    },
    computed: {
      ...mapGetters(['allClubs']),
    },
    methods: {
      ...mapActions(['removeClub']),
      editClub (club) {
        this.$router.push(`/admin/clubs/${club._id}`)
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
